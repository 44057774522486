import React from 'react'
import "../assets/css/form.css"
import "../assets/css/radio.css"
import "../assets/css/dropdown.css"

const Thanks = () => {

  return (
    <div className="row">
      <div className="col-md-8 col-md-offset-3">
        <form id="msform">
          <div>
            <h4>¡MUCHAS GRACIAS!</h4>
            <h5>HEMOS RECIBIDO CORRECTAMENTE TU SOLICITUD</h5>
            <h6>En breve un asesor se contactará contigo.</h6>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Thanks