import React from 'react'
import "./assets/css/App.css";
import { TopBar } from "./components/TopBar";
import Footer from "./components/Footer";
import Form from "./components/Form";
import Thanks from "./components/Thanks";
import { ImgPanel } from "./components/ImgPanel";
import { BottomImgPanel } from "./components/BottomImgPanel";

function App() {

  const url = window.location.pathname;

  return (
      <div className="fullPage">

        <div className="top-bar-position">
          <TopBar/>
        </div>

        <div className="form-position">
          {url === "/thanks" ? (<Thanks/>):(<Form/>)}
        </div>

        <div className="img-panel-position">
          <ImgPanel/>
        </div>

        <div className="bottom-img-panel-position">
          <BottomImgPanel/>
        </div>

        <div className="footer-position">
          <Footer/>
        </div>
      </div>
  )
}

export default App