import React, { useState } from 'react'
import { useForm } from "../hooks/useForm";
import axios from "axios";
import "../assets/css/form.css"
import "../assets/css/radio.css"
import "../assets/css/dropdown.css"

const Form = () => {

    const utms = window.location.search.substring(1).split("&");
    const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
    const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
    const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
    const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
    const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";

    const initialForm = {
      car_model:"",
      pay_method:"",
      car_delivery:"",
      nombre:"",
      telefono:"",
      whastapp:"",
      email:"",
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [emptyError, setEmptyError] = useState({});
    const [formValues, handleInputChange, reset] = useForm(initialForm);


    const handleSelectNextClick = async (event) => {
      //setSelections({...selections, [event.target.name]:event.target.value})
      handleInputChange(event);
      setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePreviousClick = async (event) => {
        event.preventDefault(); // Prevent the default form submission action
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const validar_email = (email) => {
      return /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)
    }

    const validar_tel = (tel) => {
      return tel.length > 8 && tel.length < 13
    }

    const handleSubmitClick = async (event) => {
        event.preventDefault();

        var err = {}
        for (let k in formValues){
          err = {...err, [k]:(formValues[k]==="")};
        }
        err["email"]=err["email"]||!validar_email(formValues.email)
        err["telefono"]=err["telefono"]||!validar_tel(formValues.telefono)
        err["whatsapp"]=err["whatsapp"]||!validar_tel(formValues.whastapp)

        setEmptyError(err)
        if (Object.values(err).includes(true)){
          return;
        }else {


          let lead = {
            ...formValues,
            utm_medium_c: utm_medium,    
            utm_source_c: utm_source,
            utm_content_c: utm_content,
            utm_campaign_c: utm_campaign,
            utm_term_c: utm_term,

            status: "New",
            assigned_user_id: "b07e5025-17b5-2d8f-ce5e-61895c7f48bd",
          }
          await axios({
            method: "POST",
            url: "processor.php",
            headers: {
              "Content-Type": "application/json",
            },
            data: lead,
          })
            .then((res) => {
              if (res.data.includes("success")) {
                //setCorrectValidation(1); // Seteo la variable en 1 para que en la pagina se muestre el mensaje de "Uno de nuestros representantes lo contactará a la brevedad."
                reset();
                window.location.href = '/thanks';
                //setLoading(false);
              } else {
                alert("Ha ocurrido un error inesperado!")
                //setCorrectValidation(5);
                //setLoading(false);
              }
            })
            .catch((err) => {
              console.warn(err);
                //setCorrectValidation(5); // "A ocurrido un error inesperado"
                alert("Ha ocurrido un error inesperado")
                //setLoading(false);
            });

          setTimeout(() => {
            setEmptyError({})
            //setCorrectValidation(0);
          }, 4000);
          return;
        }

    };
  return (
    <div className="row">
      <div className="col-md-8 col-md-offset-3">
        <form id="msform">
          <div style={{ display: currentStep < 6 ? 'block' : 'none' }}>
            <h1>ELEGÍ TU VOLKSWAGEN Y APROVECHÁ ESTOS BENEFICIOS</h1>
            <ul id="progressbar">
              <li className={currentStep === 0 || currentStep === 1 || currentStep === 2 || currentStep === 3  ? 'active' : ''}></li>
              <li className={currentStep === 1 || currentStep === 2 || currentStep === 3 ? 'active' : ''}></li>
              <li className={currentStep === 2 || currentStep === 3 ? 'active' : ''}></li>
              <li className={currentStep === 3 ? 'active' : ''}></li>
            </ul>
          </div>

          {/* 1. Modelo */}
          <fieldset style={{ display: currentStep === 0 ? 'block' : 'none' }}>
            <h2 className="fs-title">1. Modelo</h2>
            <h3 className="fs-subtitle">¿Qué modelo buscás?</h3>

            <div className='selection-container'>

              <label className="selection_radio" htmlFor="1_taos">Taos
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_taos" name="car_model" value="taos"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="1_polo">Polo
                <input  onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_polo" name="car_model" value="polo"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="1_nivus">Nivus
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_nivus" name="car_model" value="nivus"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="1_t-cross">T-Cross
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_t-cross" name="car_model" value="t-cross"/>
                <span className="checkmark"></span>
              </label>

            </div>
            <div className='selection-container-model-car'>

              <label className="selection_radio" htmlFor="1_virtus">Virtus
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_virtus" name="car_model" value="virtus"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="1_saveiro">Saveiro
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_saveiro" name="car_model" value="saveiro"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="1_amarok">Amarok
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_amarok" name="car_model" value="amarok"/>
                <span className="checkmark"></span>
              </label>

            </div>

          </fieldset>

          {/* 2. Tipo de propiedad */}
          <fieldset style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <h2 className="fs-title">2. Anticipo</h2>
            <h3 className="fs-subtitle">¿Cómo va a ser tu anticipo?</h3>

            <div className='selection-container-pay-method'>

              <label className="selection_radio" htmlFor="2_capital">Capital
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="2_capital" name="pay_method" value="capital"/>
                <span className="checkmark"></span>
              </label>
              <span/>
              <label className="selection_radio" htmlFor="2_vehiculo_usado">Vehículo Usado
                <input  onClick={handleSelectNextClick} className='radio_button' type="radio" id="2_vehiculo_usado" name="pay_method" value="vehiculo_usado"/>
                <span className="checkmark"></span>
              </label>

            </div>

            <div className="back-button-container">
              <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
            </div>

          </fieldset>

          {/* 3. Metros de la propiedad */}
          <fieldset style={{ display: currentStep === 2 ? 'block' : 'none' }}>
            <h2 className="fs-title">3. Entrega</h2>
            <h3 className="fs-subtitle">¿Qué tiempo contás para la entrega?</h3>

            <div className='selection-container-pay-method'>

              <label className="selection_radio" htmlFor="3_inmediata">Inmediata
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_inmediata" name="car_delivery" value="inmediata"/>
                <span className="checkmark"></span>
              </label>
              <span/>
              <label className="selection_radio" htmlFor="3_hasta_6_meses">Hasta 6 meses
                <input  onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_hasta_6_meses" name="car_delivery" value="hasta_6_meses"/>
                <span className="checkmark"></span>
              </label>
              <span/>
              <label className="selection_radio" htmlFor="3_hasta_12_meses">Hasta 12 meses
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_hasta_12_meses" name="car_delivery" value="hasta_12_meses"/>
                <span className="checkmark"></span>
              </label>

            </div>

            <div className="back-button-container">
              <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
            </div>

          </fieldset>

          {/* 4. ¿Dónde está ubicada? */}
          {/* 4. Datos personales */}
          <fieldset style={{ display: currentStep === 3 ? 'block' : 'none' }}>
          <h2 className="fs-title">4. Datos personales</h2>
            <h3 className="fs-subtitle">Para poder acercarte una oferta necesitamos contar con tus datos:</h3>

            <div className='text-input-container'>

            <input
              required={true}
              className={emptyError.nombre?"error-text":''}
              type="text"
              name="nombre"
              placeholder={emptyError.nombre?"Llene el campo nombre y apellido":"Nombre y apellido"}
              value={formValues.nombre}
              onChange={handleInputChange}
              onBlur={() => setEmptyError({...emptyError, nombre:formValues.nombre===""})}
              onFocus={() => setEmptyError({...emptyError, nombre:false})}
              onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
            />

            <input
              required={true}
              className={emptyError.telefono?"error-text":''}
              type="number"
              name="telefono"
              placeholder={emptyError.telefono?"Llene el campo teléfono":"Teléfono"}
              value={formValues.telefono}
              onChange={handleInputChange}
              onBlur={() => setEmptyError({...emptyError, telefono:formValues.telefono===""})}
              onFocus={() => setEmptyError({...emptyError, telefono:false})}
              onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
            />

            <input
              required={true}
              className={emptyError.whastapp?"error-text":''}
              type="number"
              name="whastapp"
              placeholder={emptyError.whastapp?"Llene el campo Número de Whatsapp":"Número de Whatsapp"}
              value={formValues.whastapp}
              onChange={handleInputChange}
              onBlur={() => setEmptyError({...emptyError, whastapp:formValues.whastapp===""})}
              onFocus={() => setEmptyError({...emptyError, whastapp:false})}
              onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
            />

            <input
              required={true}
              className={emptyError.email?"error-text":''}
              type="email"
              name="email"
              placeholder={emptyError.email?"Llene el campo email":"Email"}
              value={formValues.email}
              onChange={handleInputChange}
              onBlur={() => setEmptyError({...emptyError, email:(formValues.email==="")})}
              onFocus={() => setEmptyError({...emptyError, email:false})}
              onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
            />

            </div>

            <div className="final-buttons">
              <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
              <button className="submit action-button" onClick={handleSubmitClick}>Enviar</button>
            </div>
          </fieldset>

          
          <div style={{ display: currentStep === 4 ? 'block' : 'none' }}>
            <h4>¡MUCHAS GRACIAS!</h4>
            <h5>HEMOS RECIBIDO CORRECTAMENTE TU SOLICITUD</h5>
            <h6>En breve un asesor se contactará contigo.</h6>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form