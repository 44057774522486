import React from "react";
import logo from "../assets/img/logo.svg";
import logovw from "../assets/img/logoVw.svg";
import "../assets/css/topBar.css";

export const TopBar = () => {

  return (
    <div className="top-bar">
      <a href="#root">
        <img className="logo-top-bar" src={logo} alt="logo" />
      </a>
        <img className="logo-vw-top-bar" src={logovw} alt="logo"/>
    </div>
  );
};
