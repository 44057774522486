import React from "react";

import promocion1 from "../assets/img/carsImage.png";
import promocion1Mobile from "../assets/img/carsImageMobile.png";

import "../assets/css/imgPanel.css";

export const ImgPanel = (img, img_mobile) => {
  //const text = "Recibe asesoramiento sin cargo"; //??
  const text = "Alperovich Volkswagen"; //??

  return (
    <div className="panel-title">
      <div className="img-panel">
        <div className="img-panel-overlay"/>
              <img
                src={promocion1Mobile}
                alt={text}
                className="img-mobile"
              />
              <img
                src={promocion1}
                alt={text}
                className="img-desk"
              />
      </div>
    </div>
  );
};
