import React from "react";

import promocion1 from "../assets/img/taosCarImage.png";
import promocion1Mobile from "../assets/img/taosCarMobile.png";

import "../assets/css/bottomImgPanel.css";

export const BottomImgPanel = () => {
  const text = "Alperovich Volkswagen"; //??

  return (
    <div className="bottom-panel-title">
      <div className="bottom-panel-img">
              <img
                src={promocion1Mobile}
                alt={text}
                className="img-mobile"
              />
              <img
                src={promocion1}
                alt={text}
                className="img-desk"
              />
      </div>
    </div>
  );
};
